<template>
  <div class="edit-contaner b p32">
    <el-row class="title" align="middle" type="flex" justify="space-between">
      <div class="brand">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <!-- <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/contact/personal' }"
              >个人中心</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/contact/collect' }"
              >我的收藏</el-breadcrumb-item
            >
            <el-breadcrumb-item>我的收藏编辑</el-breadcrumb-item>
          </el-breadcrumb> -->
          <span class="pos" style="color: #999999">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item> -->
            <el-breadcrumb-item :to="{ path: '/contact/personal' }"
              ><span class="c9" style="font-weight: 400"
                >个人中心</span
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/contact/collect' }"
              ><span class="c9" style="font-weight: 400"
                >我的收藏</span
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item
              ><span class="c9">我的收藏编辑</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
      </div>
      <div class="comp f14 p" @click="toCmop">完成</div>
    </el-row>
    <div class="edit-box">
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="checkedCities"
        @change="handleCheckedCitiesChange"
      >
        <div class="item-box" v-for="i in cities" :key="i.id">
          <el-checkbox :label="i">
            <img class="item-img" :src="i.essayVO.mainImg" alt="" />
            <div class="text">
              <p class="line1 c3 f14">
                {{ i.essayVO.essayTitle || i.secondGoodsVO.goodsName }}
              </p>
              <p class="line2 c9 f14">{{ i.essayVO.createTime }}</p>
            </div>
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        class="select-all"
      >
        <span class="f14 c3">全选</span>
        <button @click="toDelete">删除</button>
      </el-checkbox>
    </div>
  </div>
</template>

<script>
let cityOptions = [];
export default {
  name: "YoufujichuangEdit",

  data() {
    return {
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true, //全选数据

      articleList: [], //技术中心收藏

      goodList: [], //二手机床收藏

      allList: [],

      idsArr: [], //选中数据的id
      type: "",
    };
  },

  mounted() {},
  created() {
    // 用户收藏-分页列表查询
    // this.userCollectionGoodList();
    // userCollectionArticleList
    this.type = this.$route.query.type;
    if (this.type == 2) {
      this.userCollectionGoodList();
    }
    if (this.type == 1) {
      this.userCollectionArticleList();
    }
  },

  methods: {
    // 用户收藏二手机床收藏-分页列表查询
    userCollectionGoodList() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        collectionType: 1,
      };
      this.$API
        .userCollectionList(paramData)
        .then((res) => {
          if (res.code == 1) {
            //二手机床收藏
            this.goodList = res.data.records;
            this.cities = this.goodList;
            // this.userCollectionArticleList();
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 用户收藏技术中心收藏-分页列表查询
    userCollectionArticleList() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        collectionType: 2,
      };
      this.$API
        .userCollectionList(paramData)
        .then((res) => {
          if (res.code == 1) {
            ///技术中心收藏
            this.articleList = res.data.records;
            // this.allList = [...this.articleList, ...this.goodList];
            // this.cities = this.allList;
            // cityOptions = this.allList;
            // console.log(this.cities);
            this.cities = this.articleList;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 完成
    toCmop() {
      this.$router.push("/contact/collect");
    },
    // 全选方法
    handleCheckAllChange(val) {
      if (val) {
        cityOptions.forEach((item) => {
          if (!this.idsArr.includes(item.id)) {
            this.idsArr.push(item.id);
          }
        });
      } else {
        this.idsArr = [];
      }
      // console.log(val, "val", this.idsArr);

      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      // console.log(value, "value");
      if (value.length == 0) {
        this.idsArr = [];
      } else {
        this.idsArr = [];
        //value为所有点了单选的数据
        value.forEach((item) => {
          if (!this.idsArr.includes(item.id)) {
            this.idsArr.push(item.id);
          }
        });
      }

      // console.log(this.idsArr, "idsArr");
      let checkedCount = value.length; //所有选中的数据个数
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    toDelete() {
      // console.log(this.idsArr);
      // return;
      if (this.idsArr.length == 0) {
        this.$message.info("暂无要删除内容");
        return;
      }
      let ids = this.idsArr.join(",");
      // console.log(ids, "ids");
      let paramData = {
        ids,
      };
      this.$alert("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: true,
        callback: (action) => {
          // 确定  confirm  取消 cancel
          if (action == "confirm") {
            this.$API
              .userCollectionDelete(paramData)
              .then((res) => {
                if (res.code == 1) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  this.idsArr = [];
                  // this.userCollectionGoodList(); //刷新列表
                  if (this.type == 2) {
                    this.userCollectionGoodList();
                  }
                  if (this.type == 1) {
                    this.userCollectionArticleList();
                  }
                } else {
                  this.$message.info(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.el-checkbox__inner {
  border-radius: 7px !important;
}
.edit-contaner {
  padding: 32px;
  padding-bottom: 0px;
  .title {
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
  }
  .nav-box {
    .pos {
      color: #333333;
      font-size: 16px;
    }
  }
  .comp {
    color: #007bd9;
  }
  .item-box {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    .item-img {
      width: 94px;
      height: 94px;
      margin-right: 21px;
      margin-left: 32px;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      position: relative;
      top: 26px;
      width: 431px;
      .line1 {
        width: 430px;
        height: 40px;
        margin-bottom: 22px;
        word-break: break-all;
        // @include dot(2);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .select-all {
    width: 976px;
    height: 56px;
    background: #eeeeee;
    border-radius: 0px 0px 8px 8px;
    position: relative;
    left: -32px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 32px;
    button {
      width: 112px;
      height: 56px;
      background: #ff0015;
      border-radius: 0px 0px 8px 0px;
      position: relative;
      right: -781px;
      color: #ffffff;
    }
  }
  .edit-box {
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: red;
      border-color: red;
    }
  }
  .el-checkbox__inner:hover {
    border-color: red;
  }
}
</style>